import { watch, Plugin, effectScope } from 'vue'
import { posthog } from '@/modules/base/composable/useAnalytics'
import { useCurrentUser } from '@/modules/base/composable/useCurrentUser'

export const posthogPlugin: Plugin = {
  install(app) {
    if (posthog) {
      const scope = effectScope()

      app.runWithContext(() => {
        scope.run(() => {
          const { currentUser } = useCurrentUser()

          watch(
            () => currentUser.value,
            (user) => {
              if (user) {
                if (posthog) {
                  const { id, email, firstName, lastName } = user
                  posthog.setPersonProperties({
                    name: `${firstName} ${lastName}`,
                    email,
                  })
                  posthog.identify(String(id))
                }
              } else {
                posthog?.reset()
              }
            },
            { immediate: true }
          )
        })
      })
    }
  },
}
